import ng from "angular";
import { DayOfWeek } from "js-joda";
import { Caregiver } from "../../../../scripts/messages/caregiver";
import { CaregiverRequest, CaregiverRequestsRowBindings} from "./flexible-caregiver-requests-row.types";
import "./flexible-caregiver-requests-row.component.scss";

//! @ngInject
class caregiverRequestsRowCtrl implements ng.IComponentController, CaregiverRequestsRowBindings
{
    caregiver!: Caregiver;
    requests!: CaregiverRequest[];
    visitType!: "PATTERN" | "SINGLES"
    toggleShiftSelection!: (requestDetails: {requestDetails: CaregiverRequest[]}) => void;
    allowMultipleCaregivers!: boolean;

    selectedRow!: boolean;
    
    constructor(
      private $rootScope: ng.IRootScopeService){
        this.selectedRow = false;
    }

    $onInit(){
      this.selectedRow = false;

      switch(this.visitType){
        case "PATTERN":{
          this.requests = this.mapVisitRequestsToDaysOfWeek(this.requests);
          break;
        }
        default:
          break;
      }
    }

    $onChanges(changesObj){
      if ("requests" in changesObj) {
        const requests = changesObj.requests.currentValue;
  
        if (requests.length > 0) {
          switch(this.visitType){
            case "PATTERN":            
              this.requests = this.mapVisitRequestsToDaysOfWeek(requests);
              break;
            default:
              break;
          }  
        }
      }
    }

    mapVisitRequestsToDaysOfWeek(requests: CaregiverRequest[]){
        const requestsDetails: CaregiverRequest[] = DayOfWeek.values().map((day) => {
          const requestInDay = requests.find((request) => request.day.toString() === day.name());
      
          return {
            type: "PermanentRequest",
            day,
            startTime: requestInDay === undefined ? null : requestInDay.startTime,
            endTime: requestInDay === undefined ? null : requestInDay.endTime,
            isDisabled: requestInDay === undefined,
            isSelected: false,
            caregiverId: this.caregiver.id
          }
        }) 

        return requestsDetails;
    }

  toggleShiftSelectionForCaregiver(requestDetails: CaregiverRequest){
    this.requests.forEach((request) => {
      if(request === requestDetails){
        request.isSelected = !request.isSelected;
      }
    })

    this.toggleShiftSelection({requestDetails: [requestDetails]});
  }

  toggleSelectionForAllCaregiverShifts(){
    this.selectedRow = !this.selectedRow;    

    this.requests.filter((request) => !request.isDisabled).forEach((request) => {
      request.isSelected = !request.isSelected;
    })

    this.toggleShiftSelection({requestDetails: this.requests.filter(((request) => !request.isDisabled))});
  }

  openCaregiverModal(caregiver: Caregiver){
    this.$rootScope.openCaregiverModal(caregiver.id, caregiver);
  }
}

export const flexibleCaregiverRequestsRow = {
    templateUrl:
      "admin/modules/visit-broadcast/components/flexible-caregiver-requests-row/flexible-caregiver-requests-row.component.html",
    controller: caregiverRequestsRowCtrl,
    controllerAs: "ctrl",
    bindings: {
      caregiver: "<",
      visitType: "<",
      requests: "=",
      datesRange: "<",
      toggleShiftSelection: "&",
      allowMultipleCaregivers: "<"
    },
  };