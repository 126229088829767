import ng from "angular";
import { ChronoUnit, DayOfWeek, LocalTime } from "js-joda";
import { CaregiverId, PatientId, VisitBroadcastId } from "../../../../scripts/messages/ids";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { AssignCaregiverToFlexibleVisitParams } from "../../visitBroadcast.types";
import { CaregiverRequest } from "../flexible-caregiver-requests-row/flexible-caregiver-requests-row.types";
import { CaregiverVisitRequestsContainerBindings, FlexibleCaregiverEngagement, FlexibleVisitShift } from "./flexible-visit-requests-container.types";
import { convertWeeklyTemplateModalActionToEnumValue, WeeklyTemplateActionEnum } from "./flexible-visit-requests-container.utils";

//! @ngInject
class caregiverVisitRequestsContainerCtrl implements ng.IComponentController, CaregiverVisitRequestsContainerBindings
{
    caregiverEngagements!: FlexibleCaregiverEngagement[];
    visitBroadcastId!: VisitBroadcastId;
    visitType!: "PATTERN" | "SINGLES";
    closeModal!: () => void;
    allowMultipleCaregivers!: boolean;
    patientId!: PatientId;
    totalVisitHours!: number;
    
    requests!: CaregiverRequest[];
    selectedShiftsByCaregiver!: Map<CaregiverId, Map<DayOfWeek, FlexibleVisitShift>>;
    doesPatientHaveActiveWeeklyTemplate!: boolean;
    totalSelectedHoursNum!: number;

    constructor(
      private $rootScope: ng.IRootScopeService,
      private visitBroadcastService: VisitBroadcastService,
      private toaster: toaster.IToasterService,
      private mfModal: any){
        this.selectedShiftsByCaregiver = new Map<CaregiverId, Map<DayOfWeek, FlexibleVisitShift>>();
    }

    $onInit(){
      this.visitBroadcastService.getPatientWeeklyTemplate(this.patientId).then((response) => {
        if(response.data.shifts.length > 0){
          this.doesPatientHaveActiveWeeklyTemplate = true;
        }
        else{
          this.doesPatientHaveActiveWeeklyTemplate = false;
        }
      }).catch((error) => {
        console.log(`Error in fetching patient ${this.patientId} weekly template. ${error.data}`);
      })

      this.totalSelectedHoursNum = 0;
    }

    toggleSelectShift(requestDetails: FlexibleVisitShift[]){
      for(const shift of requestDetails){
        const convertedShift = {
          ...shift,
          startTime: LocalTime.parse(shift.startTime.toString()),
          endTime: LocalTime.parse(shift.endTime.toString())
        }

        const isShiftSelected = convertedShift.isSelected;
        const amountOfShiftHours = convertedShift.startTime.until(convertedShift.endTime, ChronoUnit.HOURS);
  
        if(!isShiftSelected){
          this.selectedShiftsByCaregiver.get(convertedShift.caregiverId)?.delete(convertedShift.day);

          if(this.selectedShiftsByCaregiver.get(convertedShift.caregiverId)?.size === 0){
            this.selectedShiftsByCaregiver.delete(convertedShift.caregiverId);
          }

          this.totalSelectedHoursNum = this.totalSelectedHoursNum - amountOfShiftHours;
        }
        else{
          const shiftByCaregiver = this.selectedShiftsByCaregiver.get(convertedShift.caregiverId);
    
          if(shiftByCaregiver === undefined){
            const newDayOfWeekMap = new Map<DayOfWeek, FlexibleVisitShift>().set(convertedShift.day, convertedShift);
            this.selectedShiftsByCaregiver.set(convertedShift.caregiverId, newDayOfWeekMap);
          }
          else {
            shiftByCaregiver.set(convertedShift.day, convertedShift);
            this.selectedShiftsByCaregiver.set(convertedShift.caregiverId, shiftByCaregiver);
          }
    
          this.totalSelectedHoursNum = this.totalSelectedHoursNum + amountOfShiftHours;
        }
      }
    }

    clearSelectedShifts(){
      this.selectedShiftsByCaregiver = new Map<CaregiverId, Map<DayOfWeek, FlexibleVisitShift>>();
      this.caregiverEngagements.forEach((engagement) => 
        engagement.flexibleVisitRequest.forEach((request) => request.isSelected = false)
      );

      this.totalSelectedHoursNum = 0;
    }

    mapSelectedShiftsToRequestBodyParams(weeklyTemplateAction){
      const assignParams: AssignCaregiverToFlexibleVisitParams[] = [];

      for(const [caregiverId, shiftsByDay] of this.selectedShiftsByCaregiver.entries()){
        const caregiverShifts: {
          day: DayOfWeek,
          startTime: LocalTime,
          endTime: LocalTime
        }[] = [];

        for(const [_day, shift] of shiftsByDay.entries()){
          const currShiftDetails = {
            day: shift.day,
            startTime: LocalTime.parse(shift.startTime.toString()),
            endTime: LocalTime.parse(shift.endTime.toString())
          }

          caregiverShifts.push(currShiftDetails);
        }

        assignParams.push({
          caregiverId: caregiverId,
          shifts: caregiverShifts
        })
      }

      return {
        assignParams: assignParams,
        weeklyTemplateAction: convertWeeklyTemplateModalActionToEnumValue(weeklyTemplateAction) 
      };
    }

    assignShifts(weeklyTemplateAction){
      const requestBody = this.mapSelectedShiftsToRequestBodyParams(weeklyTemplateAction);

      this.visitBroadcastService.assignCaregiversToFlexibleVisit(this.visitBroadcastId, requestBody).then(
        (res) => {
          if (res.data.assignedWithOvertime) {
            this.toaster.pop({
              type: "warning",
              title: "Warning",
              body: `Successfully assigned caregiver with increased caregiver overtime`,
            });
          } else {
            this.toaster.pop("success", "Successfully assigned caregiver");
          }
          this.$rootScope.$emit("refresh_visits");
          this.closeModal();
        },
        (err) => {
          let errorMessage = "Failed to assign caregiver";
          if (err.status === 403) {
            if(err.data.details.includes("OVERLAP ISSUE")){
              errorMessage = "New Visits overlap with caregiver of patient";
            }
            else{
              errorMessage = "Not permitted to increase caregiver overtime.";
            }
          }
          this.toaster.pop("error", "Oops...", errorMessage);
        }
      );
    }

    promptWeeklyTemplateQuestions(){
        const message = "What would you like to do?"
        const options: {id: number, action: WeeklyTemplateActionEnum, label: string, tooltipText?: string}[] = [];
        let subject = '';

        if(!this.doesPatientHaveActiveWeeklyTemplate){
          options.push(
            { id: 2, action: "NO_EFFECT", label: "Add visits without creating a new Weekly Template" },
            { id: 3, action: "ADD", label: "Add visits and create a new Weekly Template"});
          subject = `This patient doesn't have an active Weekly Template`;
        }
        else{
          options.push(
            { id: 2, action: "NO_EFFECT", label: "Add visits without any change to the existing Weekly Template" });
          options.push(
            { id: 3, action: "ADD", label: "Add to the existing Weekly Template"}
          );
          options.push({
            id: 1,
            action: "REPLACE",
            label: "Replace the existing Weekly Template",
            tooltipText: "Days with visits will be replaced\r\nDays without visits will be added"
          })
          subject = `This patient already has an active Weekly Template`;
        }

        const modal = this.mfModal.create({
            subject,
            options: options,
            message: message,
            layoutOrder: ["message", "options"],
            confirmLabel: "Submit",
            hideCancelButton: false,
            preventClose: true,
            onConfirm: ({ selectedOption }) => {
              modal.setLoading(true);
              if (selectedOption) {
                this.assignShifts(selectedOption);
              }
    
              // No selected option
              modal.update({
                isLoading: false,
                message: "Please select option"
              });
            },
            onCancel: () => modal.close(),
            onComplete: () => modal.close()
          });
        }
}

export const flexibleVisitRequestsContainer = {
    templateUrl:
      "admin/modules/visit-broadcast/components/flexible-visit-requests-container/flexible-visit-requests-container.component.html",
    controller: caregiverVisitRequestsContainerCtrl,
    controllerAs: "ctrl",
    bindings: {
        caregiverEngagements: "<",
        visitBroadcastId: "<",
        visitType: "<",
        closeModal: "&",
        allowMultipleCaregivers: "<",
        patientId: "<",
        totalVisitHours: "<"
    },
  };