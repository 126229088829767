
/* @ngInject */
export function generalUtils($filter, $location, DatabaseApi, $http, $q, $sce, mfModal) {

    const sort = (name, a, b) => {
        if (a[name].toLowerCase() < b[name].toLowerCase()) {
        return -1;
        } else if (a[name].toLowerCase() > b[name].toLowerCase()) {
        return 1;
        }
        return 0;  
    };

    const distanceInKm = (lat1, lon1, lat2, lon2) => {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;
    
        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    };

    var generalUtils = {

        downloadURI: function (uri, name) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function () {
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(xhr.response);
                a.download = name;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
            };

            xhr.open('GET', uri);
            xhr.send();
        },

        generateQueryStringParam: function (paramName, paramValue) {
            return paramName && paramValue ? ("&" + paramName + "=" + paramValue) : "";
        },

        formatDateByFilter: function (dateStringValue, formatTemplate) {
            var formattedString = dateStringValue && $filter("date")(new Date(dateStringValue), formatTemplate || 'yyyy-MM-dd');
            return formattedString || null;
        },

        getLastPartInUrlPath: function () {
            var relativePath = window.location.pathname;
            var pathParts = relativePath.split('/');
            var lastUrlPath = pathParts[pathParts.length - 1];
            return lastUrlPath;
        },

        getUrlExtension(url) {
            return url.split(/\#|\?/)[0].split('.').pop().trim();
        },

        getQueryStringParams: function () {
            var queryString = window.location.search.substr(1);
            var queryParams = null;
            if (queryString) {
                queryParams = {};
                var queryStringParts = queryString && queryString.split('&');
                queryStringParts.forEach(function (expression) {
                    var paramParts = expression.split('=');
                    var paramName = paramParts[0];
                    var paramValue = paramParts[1] && paramParts[1].indexOf('|') >= 0
                        ? paramParts[1].split('|')
                        : paramParts[1] && paramParts[1].indexOf(encodeURI('|')) >= 0 ? paramParts[1].split(encodeURI('|')) : paramParts[1];
                    queryParams[paramName] = paramValue;
                });
            }
            return queryParams;
        },

        navigateToStateUrl: function ($state, stateRoute, queryString) {
            var locationPath = $location.path();
            var lastUrlPartIndex = locationPath.lastIndexOf('/');
            var routeUrl = $state.get(stateRoute).url;
            var targetUrl = (lastUrlPartIndex > 0 ? locationPath.substr(0, lastUrlPartIndex) : locationPath) + routeUrl + queryString;
            $location.url(targetUrl);
        },

        sortWithEmptyValuesAtEnd: function (filter, ngTableParams, fieldName, data) {
            var orderedData = data.slice(0, data.length);
            var sorting = ngTableParams.sorting();

            if (sorting[fieldName]) {
                // This is opposite because the first elements are shown first and we want "up" to be first and not last.
                var orderMulti = sorting[fieldName] === 'asc' ? -1 : 1;
                orderedData.sort(function (a, b) {
                    if (a[fieldName]) {
                        if (b[fieldName] && a[fieldName] === b[fieldName]) return 0;
                        else if (b[fieldName] && a[fieldName] < b[fieldName]) return -1 * orderMulti;
                        else if (b[fieldName] && a[fieldName] > b[fieldName]) return 1 * orderMulti;

                        // If b is null, we want a to be earlier.
                        return -1;
                    }
                    else if (!a[fieldName]) {
                        if (!b[fieldName]) return 0;

                        // If a is null but b isn't, we want a to go up.
                        return 1;
                    }
                });
            } else {
                orderedData = sorting ? $filter('orderBy')(data, ngTableParams.orderBy()) : data;
            }

            orderedData = $filter('filter')(orderedData, ngTableParams.filter());

            ngTableParams.total(orderedData.length);
            orderedData = orderedData.slice((ngTableParams.page() - 1) * ngTableParams.count(), ngTableParams.page() * ngTableParams.count());
            return orderedData;
        },

        levenshtein: (a, b) => {
            if (a.length === 0) return b.length
            if (b.length === 0) return a.length
            let tmp, i, j, prev, val, row
            if (a.length > b.length) {
                tmp = a
                a = b
                b = tmp
            }

            row = Array(a.length + 1)
            for (i = 0; i <= a.length; i++) {
                row[i] = i
            }

            for (i = 1; i <= b.length; i++) {
                prev = i
                for (j = 1; j <= a.length; j++) {
                    if (b[i - 1] === a[j - 1]) {
                        val = row[j - 1] // match
                    } else {
                        val = Math.min(row[j - 1] + 1, Math.min(prev + 1, row[j] + 1))
                    }
                    row[j - 1] = prev
                    prev = val
                }
                row[a.length] = prev
            }
            return row[a.length]
        },

        getDocumentContentFromURL: (url) => {
            const deferred = $q.defer();
            let doc = {};
            DatabaseApi.get(url).then(function (res) {
                doc.imageUrl = res.data.fileUrl;
                doc.fileExtension = doc.imageUrl.split(/\#|\?/)[0].split('.').pop().trim();
                if (doc.fileExtension === 'pdf') {
                    $http.get(doc.imageUrl,
                        { responseType: 'arraybuffer' })
                        .then(function (response) {
                            var file = new Blob([response.data], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            doc.content = $sce.trustAsResourceUrl(fileURL);
                            deferred.resolve(doc);
                        });
                } else {
                    deferred.resolve(doc);
                }
            }, function (err) {
                deferred.reject(err);
            });
            return deferred.promise;
        },

        intersect: (a, b) => {
            let ai = 0
            let bi = 0;
            const result = [];
    
            while( ai < a.length && bi < b.length )
            {
                if (a[ai] < b[bi] ) {
                    ai++;
                } else if (a[ai] > b[bi]) {
                    bi++;
                }
                else {
                    result.push(a[ai]);
                    ai++;
                    bi++;
                }
            }
            return result;
        },

        capitalize: (text) => {
            if (!text) return '';
            var words = text.split(' ');
            var capitalized = '';
            words.forEach(function (word, index) {
                if (index > 0) {
                    capitalized += ' ';
                }
                capitalized += word[0].toUpperCase() + word.slice(1).toLowerCase();
            });
            return capitalized;
        },

        getMonthDays: (date) => {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        },

        isSsnValid: (ssn) => {
            if (!ssn || typeof ssn !== "string") return false;

            // find area number (1st 3 digits, no longer actually signifies area)
            const area = parseInt(ssn.substring(0, 3));
            return (
                // 9 characters
                ssn.length === 11 &&
                // basic regex
                ssn.match(/^[0-8]{1}[0-9]{2}-[0-9]{2}-[0-9]{4}/) &&
                // disallow Satan's minions from becoming residents of the US
                area !== 666 &&
                // it's not triple nil
                area !== 0 &&
                // fun fact: some idiot boss put his secretary's ssn in wallets
                // he sold, now it "belongs" to 40000 people
                ssn !== '078-05-1120' &&
                // was used in an ad by the Social Security Administration
                ssn !== '219-09-9999'
            );
        },

        isMedicaidNumberValid: (medicaidNumber, state) => {
            if (!medicaidNumber || typeof medicaidNumber !== "string") return false;
            if (!state || typeof state !== "string") return false;

            // list of allowed pattern by states
            const stateMedicaidRegexPatternMap = {
                'NY': /^[A-Z]{2}\d{5}[A-Z]{1}$/,
                'PA': /^\d{10}$/
            };

            // we don't endforce states without patterns
            if (!stateMedicaidRegexPatternMap.hasOwnProperty(state)) return true;

            return stateMedicaidRegexPatternMap[state].test(medicaidNumber);
        },

        constructInvalidMedicaidMessage: (state) => {
            const stateMedicaidAllowedPatternMap = {
                'NY': 'AANNNNNA',
                'PA': 'NNNNNNNNNN'
            };

            if (!stateMedicaidAllowedPatternMap.hasOwnProperty(state)) return null;

            const legend = "\n\nA - capitalized letter\n N - number"
            const message = "You used a wrong Medicaid format,\n" +
                "Please use '" + stateMedicaidAllowedPatternMap[state] + "' format for " +
                state + " state.";

            return message + legend;
        },

        sortByCode: (a, b) => sort('code', a, b),

        sortByName: (a, b) => sort('name', a, b),

        sortByText: (a, b) => sort('text', a, b),
        
        sortByDisplayName: (a, b) => sort('displayName', a, b),

        distanceInKm, 

        distanceInMiles: (lat1, lon1, lat2, lon2) => distanceInKm(lat1, lon1, lat2, lon2) * 0.621371,

        isHTMLString: (str) => /<\/?[a-z][\s\S]*>/i.test(str),

        validateCaregiverEligibleActiveStatus: (hireDate, ssn) => {
            const requriedFields = {
                'Hire Date': true,
                'SSN': true
            };

            requriedFields['Hire Date'] = hireDate !== null;
            requriedFields['SSN'] = ssn !== null && ssn !== "";
            const hasMissingFields = Object.keys(requriedFields).some(field => {
                return requriedFields[field] === false;
            });

            if (!hasMissingFields) return true;

            const missingFieldsText = Object.keys(requriedFields)
                .filter(field => requriedFields[field] === false).join("\n");

            const modal = mfModal.create({
                subject: "Caregiver status cannot be changed to active",
                message: `Missing fields:\n${missingFieldsText}`,
                variant: "danger",
                hideCancelButton: true,
                confirmLabel: "OK",
                onConfirm: () => modal.close()
            });
            return false;
        },
        
        isSameDayNativeDate: (d1, d2) =>
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate(),

        scrollToElement: (elementId) => {
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    return generalUtils;

};