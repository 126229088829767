import { CaregiverId, PatientId, VisitBroadcastId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";
import { AssignCaregiversToFlexibleVisitRequest, AssignFlexibleVisitResponse, PartialAssignResponse, PatientWeeklyTemplateShiftsResponse, RejectCaregiverVisitRequestsBody, VisitBroadcastPartialAssingParams } from "./visitBroadcast.types";

//! @ngInject
export class VisitBroadcastService {
    constructor(
      private api: Api,
      private endpoint: Endpoint,
      private $rootScope: ng.IRootScopeService
    ) {}

    rejectCaregiverVisitRequest = (caregiverId: CaregiverId, visitBroadcastId: VisitBroadcastId, requestBody: RejectCaregiverVisitRequestsBody) => {
        const url = this.endpoint({
            path: "agency/:agencyId/agency_members/:agencyMemberId/visits/:visitBroadcastId/caregivers/:caregiverId/ignore_request2",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              visitBroadcastId,
              caregiverId
            },
          });

        return this.api.put(url, requestBody);
    }

    assignCaregiversToVisit = (visitBroadcastId: VisitBroadcastId, requestBody: VisitBroadcastPartialAssingParams) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_caregivers_partially",
            params: {
              agencyId: this.$rootScope.agencyId,
              agencyMemberId: this.$rootScope.agencyMemberId,
              visitBroadcastId
            },
          });

        return this.api.post<PartialAssignResponse>(url, requestBody);
    }

    assignCaregiversToFlexibleVisit = (visitBroadcastId: VisitBroadcastId, requestBody: AssignCaregiversToFlexibleVisitRequest) => {
      const url = this.endpoint({
          path: "agencies/:agencyId/agency_members/:agencyMemberId/broadcast_visits/:visitBroadcastId/assign_flexible_visit",
          params: {
            agencyId: this.$rootScope.agencyId,
            agencyMemberId: this.$rootScope.agencyMemberId,
            visitBroadcastId
          },
        });

      return this.api.post<AssignFlexibleVisitResponse>(url, requestBody);
  }

  getPatientWeeklyTemplate = (patientId: PatientId) => {
    const url = this.endpoint({
        path: "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/weekly_template_shifts",
        params: {
          agencyId: this.$rootScope.agencyId,
          agencyMemberId: this.$rootScope.agencyMemberId,
          patientId
        },
      });

    return this.api.get<PatientWeeklyTemplateShiftsResponse>(url);
}
}