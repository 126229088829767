import moment from "moment";
import $ from "jquery";

export function newVisitTimeSpan() {
    return {
        restrict: "E",
        templateUrl: "admin/views/new-visit-time-span.html",
        scope: {
            form: '=',
            getDefaultShift: '<',
            onUseAuthorizationDates: '<',
            activeTab: '<',
            singleTabDisabled: '<',
            permanentTabDisabled: '<',
            editMode: '<',
            showSeparateShifts: '<',
            selectedVisits: '=',
            selectedShiftChanged: '&',
            removeSelectedShift: '&',
            applyShiftTimesToAll: '&',
            applyShiftCaregiverToAll: '&',
            relevantVisitCaregivers: '=',
            handleSeeAllSuggestions: '&',
            handleCaregiverSelect: '&',
            handleCaregiverDeselect: '&',
            handleUpdateTimesData: '&',
            updateEnableCaregivers: '&',
            clearShiftTimes: '&',
            updateTotalTimePerWeek: '&',
        },
        controller: "newVisitTimeSpanCtrl"
    };
};

//! @ngInject
export function newVisitTimeSpanCtrl(
    $scope,
    visitConsts,
    $rootScope,
    toaster,
    $timeout
) {
    function initialize() {
        $scope.advancedDays = visitConsts.advancedDays;
        $scope.times = visitConsts.times;
        initTimeSpanTabs();
        initSingleVisitData();
        initFlexibleVisitTabs();
        $scope.showFlexible = $rootScope.agencyId === 6205; // If that's our agency
    }

    const initTimeSpanTabs = () => {
        $scope.timeSpanTabs = {
            Single: {
                id: 0,
                name: "Single",
                src: "admin/views/new-visit/new-visit-single.html",
                isDisabled: $scope.singleTabDisabled,
            },
            Permanent: {
                id: 1,
                name: "Permanent",
                src: "admin/views/new-visit/new-visit-permanent.html",
                isDisabled: $scope.permanentTabDisabled,
            }
        };
        $scope.activeTimeSpanTabId = $scope.activeTab;
        $scope.flexibleTabId = 2;
        if ($scope.activeTimeSpanTabId === 0) {
            $scope.form.visitType = "Single";
        } else if ($scope.activeTimeSpanTabId === 1) {
            $scope.form.visitType = "Permanent";
        }
    };

    const initFlexibleVisitTabs = () => {
        $scope.flexibleVisitTabs = {
            notFlexible: {
                id: 2,
                name: "Not flexible",
                src: "admin/views/flexible-visit/permanent-visit-not-flexible.html",
                isDisabled: false,
            },
            flexibleHours: {
                id: 3,
                name: "Flexible hours",
                src: "admin/views/flexible-visit/permanent-visit-flexible-hours.html",
                isDisabled: false,
            },
            flexibleDays: {
                id: 4,
                name: "Flexible days",
                src: "admin/views/flexible-visit/permanent-visit-flexible-days.html",
                isDisabled: false,
            },
            flexibleDaysAndHours: {
                id: 5,
                name: "Flexible days and hours",
                src: "admin/views/flexible-visit/permanent-visit-flexible-days-and-hours.html",
                isDisabled: false,
            }
        };
        $scope.flexibleTabId = 2;
    };
    
    $scope.$on('time-span-tabs-changes', function () {
        $timeout(initTimeSpanTabs);
    });

    const initSingleVisitData = () => {
        $scope.singleVisitDatePickerOptions = {
            datepickerMode: 'day',
            startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
            // minDate: new Date()
        };
        $scope.form.weeksDiff = 0;
        $scope.form.daysDiff = 0;
    };

    $scope.onUpdateTimesData = (shiftId, dayName, key, newVal) => {
        $scope.handleUpdateTimesData()(shiftId, dayName, key, newVal);
    }

    $scope.onSelectedShiftChanged = (shift, key, newVal) => {
        $scope.selectedShiftChanged()(shift, key, newVal);
    };

    $scope.onTimeSpanTabClick = (tab) => {
        if (tab.isDisabled || $scope.editMode) {
            return;
        }
        $scope.activeTimeSpanTabId = tab.id;
        $scope.flexibleTabId = 2;
        
        $scope.form.visitType = tab.name;
        $scope.form.weeksDiff = 0;
        $scope.form.daysOfTheWeek = {};
        $scope.form.shifts = 1;
        if ($scope.form.shiftsPerDays !== undefined) {
            $scope.form.shiftsPerDays["Everyday"] = 1;
            $scope.advancedDays.forEach(day => $scope.form.shiftsPerDays[day.fullName] = 1);
        }
        $scope.toggleAllDays = false;
        $scope.form.isDifferentDayTimes = false;
        $scope.form.authorizations.forEach(auth => auth.isUsed = false);
        $scope.form.showPatientAuthorization = false;
        $scope.form.authorizationRow = null;
        $scope.form.hideEndDateFromCaregiver = false;

        switch (tab.name) {
            case "Single":
                $scope.form.advancedTimeShiftsSingle = [
                    $scope.getDefaultShift(0),
                    $scope.getDefaultShift(1)
                ];
                const date = new Date($scope.form.datePicker.date.startDate);
                $scope.form.datePicker.date.startDate = date;
                $scope.form.datePicker.date.endDate = date;
                break;
            case "Permanent":
                $scope.form.advancedTimeShiftsPermanent = [
                    { "Everyday": $scope.getDefaultShift(0) },
                    { "Everyday": $scope.getDefaultShift(1) }
                ];
                const day = moment($scope.form.datePicker.date.startDate).format("dddd");
                $scope.form.daysOfTheWeek[day] = true;
                $scope.updateTotalTimePerWeek()();
                break;
            default: break;
        }
    };

    $scope.onflexibleTabClick = (tab) => {
        if (tab.isDisabled || $scope.editMode) {
            return;
        }
        $scope.flexibleTabId = tab.id;
        $scope.form.visitType = tab.name;
    };

    $scope.handleChangeSingleDatePicker = () => {
        const date = $scope.form.datePicker.date.startDate;
        $scope.form.datePicker.date = {
            startDate: date,
            endDate: date
        };
    };

    $scope.openPermanentVisitCalendar = () => {
        $('#new-visit-permanent-date-picker').data('daterangepicker').show();
    };

    $scope.handleCheckAuthorization = (row) => {
        $scope.onUseAuthorizationDates(row);
    };

    $scope.updateTimesData = (shiftId, startend, dayFullName, newVal) => {
        const shift = getShiftByShiftIdAndDayFullName(shiftId, dayFullName);
        if (!startend || !shift[startend]) return;
        
        if (newVal) {
            shift[startend] = newVal;
        }
        updateShiftsDates(dayFullName);
        updateOverNight(shift);
        updateShiftDuration(shift);

        if ($scope.form.visitType === "Permanent") {
            $scope.updateTotalTimePerWeek()();
        }

        const shiftsAmount = $scope.form.shiftsPerDays === undefined ?
            $scope.form.shifts :
            $scope.form.shiftsPerDays[dayFullName];
        if (shiftsAmount > 1) {
            checkShiftsOverlapse(dayFullName);
        }
    };

    const updateShiftDuration = (shift) => {
        const mDur = moment.duration(shift.endTime.diff(shift.startTime));
        let durationHours = Math.floor(mDur.asHours());
        let durationMinutes = Math.floor(mDur.asMinutes()) % 60;
        if (durationHours < 0) durationHours += 24;
        if (durationMinutes < 0) durationMinutes += 60;

        shift.duration = getDurationStringByHoursAndMinutes(durationHours, durationMinutes);
        shift.durationHours = durationHours;
        shift.durationMinutes = durationMinutes;
    };

    const checkShiftsOverlapse = (dayFullName) => {
        const shift1 = getShiftByShiftIdAndDayFullName(0, dayFullName);
        const shift2 = getShiftByShiftIdAndDayFullName(1, dayFullName);
        const timesArray = [shift1.startTime, shift1.endTime, shift2.startTime, shift2.endTime];

        for (let i = 1; i < timesArray.length; i++) {
            if (timesArray[i].isBefore(timesArray[i - 1])) {
                let errorMessage = "Shifts overlapse..";
                if (dayFullName !== undefined && dayFullName !== "Everyday") {
                    errorMessage = dayFullName + " shifts overlapse...";
                }
                return toaster.pop("warning", "Oops...", errorMessage);
            }
        }

        if (Math.floor(moment.duration(shift2.endTime.diff(shift1.startTime)).asMinutes()) > 1440) {
            toaster.pop("warning", "Shifts 24 hours overflow");
        }

    };

    const updateOverNight = (shift) => {
        shift.isOverNight = shift.startTime.date() !== shift.endTime.date();
    };

    const updateShiftsDates = (dayFullName) => {
        const startMoment = moment($scope.form.datePicker.date.startDate);
        const startDate = startMoment.date();
        const shift1 = getShiftByShiftIdAndDayFullName(0, dayFullName);
        shift1.startTime = startMoment.clone().set("hours", shift1.startTime.hours()).set("minutes", shift1.startTime.minutes());
        shift1.endTime = startMoment.clone().set("hours", shift1.endTime.hours()).set("minutes", shift1.endTime.minutes());

        const isShift1OverNight = shift1.endTime.isBefore(shift1.startTime) || shift1.endTime.isSame(shift1.startTime);
        if (isShift1OverNight) {
            shift1.endTime.set("date", startDate + 1);
        }

        const shiftsAmount = $scope.form.shiftsPerDays === undefined ?
            $scope.form.shifts :
            $scope.form.shiftsPerDays[dayFullName];
        if (shiftsAmount > 1) {
            const shift2 = getShiftByShiftIdAndDayFullName(1, dayFullName);
            shift2.startTime = startMoment.clone().set("hours", shift2.startTime.hours()).set("minutes", shift2.startTime.minutes());
            shift2.endTime = startMoment.clone().set("hours", shift2.endTime.hours()).set("minutes", shift2.endTime.minutes());

            if (
                isShift1OverNight ||
                shift2.startTime.isBefore(shift1.startTime) ||
                shift2.startTime.isSame(shift1.startTime)
            ) {
                shift2.startTime.set("date", startDate + 1);
                shift2.endTime.set("date", startDate + 1);
            }

            if (shift2.endTime.isBefore(shift2.startTime) || shift2.endTime.isSame(shift2.startTime)) {
                shift2.endTime.add(1, "days");
            }
        }
    };

    const getShiftByShiftIdAndDayFullName = (shiftId, dayFullName) => {
        if ($scope.form.visitType === "Single" || dayFullName === undefined) {
            return $scope.form.advancedTimeShiftsSingle[shiftId];
        } else if ($scope.form.visitType === "Permanent") {
            return $scope.form.advancedTimeShiftsPermanent[shiftId][dayFullName];
        }
        return {};
    };

    const getDurationStringByHoursAndMinutes = (hours, minutes) => {
        let durationString = "";
        if (hours > 0) durationString += hours + "H";
        if (minutes > 0) {
            if (durationString !== "") durationString += ":";
            durationString += minutes + "M";
        }

        return durationString;
    };

    $scope.toggleShiftsAmount = (dayFullName) => {
        if ($scope.form.shiftsPerDays === undefined) {
            $scope.form.shifts = 3 - $scope.form.shifts;
        } else {
            $scope.form.shiftsPerDays[dayFullName] = 3 - $scope.form.shiftsPerDays[dayFullName];
            multiShiftsDay = $scope.$ctrl.advancedDays.find(day =>
                $scope.form.shiftsPerDays[day.fullName] === 2
            );
            $scope.form.shifts = (multiShiftsDay !== undefined) ? 2 : 1;
        }

        if ($scope.form.visitType === "Permanent") {
            $scope.updateTotalTimePerWeek()();
        }
    };

    initialize();
};
