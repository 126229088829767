export type WeeklyTemplateActionEnum = "REPLACE" | "ADD" | "NO_EFFECT";

const weeklyTemplateActions = new Map<number, WeeklyTemplateActionEnum>();
weeklyTemplateActions.set(1, "REPLACE");
weeklyTemplateActions.set(2, "NO_EFFECT");
weeklyTemplateActions.set(3, "ADD");


export function convertWeeklyTemplateModalActionToEnumValue(actionNum: 1 | 2 | 3){
    if(weeklyTemplateActions.get(actionNum) === undefined){
        throw new Error("Could not get weekly template action.")
    }

    return weeklyTemplateActions.get(actionNum);
}