import { LocalDateTime, Instant, ZoneId } from "js-joda";
import moment from "moment";
import _ from "lodash";

//! @ngInject
export function visitsCtrl(
    $scope,
    $rootScope,
    NgTableParams,
    $uibModal,
    DatabaseApi,
    toaster,
    $filter,
    Analytics,
    VisitStatus,
    wildcard,
    Storage,
    $timeout,
    generalUtils,
) {
    window.scrollTo(0, 0);

    // indicates if we are working/showing the visits "working set" or the filters visit set
    $scope.loadingFromDb = false;
    $scope.broadcastedTo = [];

    const DEFAULT_FILTER_BY = {
        search_string: '',
        agency_members: [], // Will be oeverrided
        createdDate: { startDate: null, endDate: null },
        updatedDate: { startDate: moment().subtract(2, "weeks").startOf("day"), endDate: moment().endOf("day") },
        startDate: { startDate: null, endDate: null },
        endDate: { startDate: null, endDate: null },
    };

    $scope.statusMap = {
        unstaffed: {
            text: "Unstaffed",
            color: "#ff835f",
            backgroundColor: "#ff835f",
            count: 0
        },
        pending: {
            text: "Pending",
            color: "#ffeb3b",
            backgroundColor: "#fff38a",
            count: 0
        },
        waiting: {
            text: 'Awaiting Assignment',
            color: "#ff8f00",
            backgroundColor: "#ffcd7d",
            count: 0
        },
        scheduled: {
            text: "Assigned",
            color: "#66bb6a",
            backgroundColor: "#D1FAE5",
            count: 0
        },
    };

    const initStatuseCounts = () => {
        $scope.statusMap['unstaffed'].count = 0;
        $scope.statusMap['pending'].count = 0;
        $scope.statusMap['waiting'].count = 0;
        $scope.statusMap['scheduled'].count = 0;
    }

    const initialize = () => {
        $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
        $scope.patients = DatabaseApi.patients() || {};
        $scope.caregiversMap = DatabaseApi.caregivers() || {};

        initStatuseCounts();
        initFilters();
        initCoordinators();
        initTableColumns();
        if (checkAssets()) {
            initVisits();
        }
    }

    const initFilters = () => {
        $scope.filterBy = angular.copy(DEFAULT_FILTER_BY);
    };

    const initCoordinators = () => {
        if ($scope.initializeMap['agencyMembers'] !== true) {
            DatabaseApi.loadAgencyMembers();
            return;
        }
        const agencyMembersArr = DatabaseApi.getAllAgencyMembersArr().filter(x => x.status === "Active" || x.hasCasesNotDischarged);
        const adminJobTitles = ["Admin", "HeadOfCoordination"];
        const allowedCoordinatorsJobTitles = ["Coordinator", "SeniorCoordinator"];
        const isUserAdminJobTitles = adminJobTitles.includes($rootScope.user.agencyMember.jobTitle);
        $scope.coordinators = agencyMembersArr.map(c => {
            c.label = `${c.firstName} ${c.lastName}`;
            return c;
        });
        const dropdownAllowedJobTitles = isUserAdminJobTitles ?
            allowedCoordinatorsJobTitles.concat(adminJobTitles) :
            allowedCoordinatorsJobTitles;
        $scope.coordinatorsOptions = $scope.coordinators.filter(c =>
            dropdownAllowedJobTitles.includes(c.jobTitle)
        );
        if (isUserAdminJobTitles) {
            $scope.filterBy.agency_members = $scope.coordinatorsOptions.map(c => ({ id: c.id }));
        } else {
            const agencyMemberOption = $scope.coordinatorsOptions.find(c => c.id === $rootScope.agencyMemberId);
            if (agencyMemberOption !== undefined) {
                $scope.filterBy.agency_members = [{ id: agencyMemberOption.id }];
            }
        }
    };

    $scope.onVisitInstanceClick = (instance, visit) => {
        if (visit.status !== 'scheduled') {
            if (visit.type === 'broadcast') {
                $rootScope.openVisitModal({visit});            
            } else {
                $rootScope.openVisitInstanceModal(instance.id, visit.patientId)
            }
        }
    }

    $scope.$watch("filterBy", () => {
        const filters = {};

        if ($scope.filterBy.search_string.length > 0) {
            filters.search_string = $scope.filterBy.search_string;
        }
        if ($scope.filterBy.agency_members.length > 0) {
            filters.agency_members = $scope.filterBy.agency_members.map(x => x.id);
        }
        if ($scope.filterBy.createdDate.startDate !== null && $scope.filterBy.createdDate.endDate !== null) {
            filters.created_from = $scope.filterBy.createdDate.startDate.format("YYYY-MM-DD")
            filters.created_to = $scope.filterBy.createdDate.endDate.format("YYYY-MM-DD")
        }
        if ($scope.filterBy.updatedDate.startDate !== null && $scope.filterBy.updatedDate.endDate !== null) {
            filters.updated_from = $scope.filterBy.updatedDate.startDate.format("YYYY-MM-DD")
            filters.updated_to = $scope.filterBy.updatedDate.endDate.format("YYYY-MM-DD")
        }
        if ($scope.filterBy.startDate.startDate !== null && $scope.filterBy.startDate.endDate !== null) {
            filters.start_from = $scope.filterBy.startDate.startDate.format("YYYY-MM-DD")
            filters.start_to = $scope.filterBy.startDate.endDate.format("YYYY-MM-DD")
        }
        if ($scope.filterBy.endDate.startDate !== null && $scope.filterBy.endDate.endDate !== null) {
            filters.end_from = $scope.filterBy.endDate.startDate.format("YYYY-MM-DD")
            filters.end_to = $scope.filterBy.endDate.endDate.format("YYYY-MM-DD")
        }

        initVisits(filters)
    }, true);

    $scope.resetFilterDateRange = (field) => {
        $scope.filterBy[field] = { startDate: null, endDate: null}
    }

    $scope.chatFilter = { val: '' };

    function getLastViewed(chat, type) {
        var last;
        chat.members.forEach(function (member) {
            if (member.user.type === type) {
                var l = new Date(member.lastViewed).getTime();
                if (!last || last < l) last = l;
            }
        });
        return last;
    }

    $scope.dateRangeOptions = {
        ranges: {
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 14 Days": [moment().subtract(13, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "Last Year": [
                moment().subtract(1, "year").startOf("year"),
                moment().subtract(1, "year").endOf("year"),
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
            ],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
        },
        alwaysShowCalendars: true,
        applyClass: "btn-primary",
        locale: {
            direction: "ltr date-range-picker-v2",
            format: "D MMM YY"
        },
        opens: "left",
        autoApply: true,
        minDate: new Date("2001-01-01"),
    };

    $scope.exportTable = () => {
        $scope.isExporting = true;
        var rows = [];
        var titles = [
            "Patient Name",
            "Patient ID",
            "Caregiver Name",
            "Instances",
            "Created By",
            "Created At",
            "Updated At",
            "Start At",
            "End At",
            "Address",
            "Certifications",
            "Status",
        ];

        rows.push(titles);

        $scope.visits.forEach(visit => {
            var row = [];
            titles.forEach(title => {
                switch (title) {
                    case "Patient Name":
                        row.push(visit.patientName || '');
                        break;
                    case "Patient ID":
                        row.push(visit.patientAdmissionId || '');
                        break;
                    case "Caregiver Name":
                        var nameToPush = '';
                        if (visit.assignedCaregivers) {
                            nameToPush = visit.assignedCaregivers.map(caregiver =>
                                caregiver.firstName + " " + caregiver.lastName
                            ).join(" | ");
                        }
                        row.push(nameToPush.replace('#', ''));
                        break;
                    case "Instances":
                        let toPush = "";
                        if (visit.visitInstances) {
                            toPush = visit.visitInstances.length
                        } else if (visit.visitInstancesCount) {
                            toPush = visit.visitInstancesCount;
                        }
                        row.push(toPush);
                        break;
                    case "Created By":
                        var name = visit.createdBy.firstName + ' ' + visit.createdBy.lastName;
                        row.push(name);
                        break;
                    case "Created At":
                        row.push($filter("mfShortTime")(visit.createdAt, ['withDate']) || '');
                        break;
                    case "Updated At":
                        row.push($filter("mfShortTime")(visit.updatedAt, ['withDate']) || '');
                        break;
                    case "Start At":
                        row.push($filter("mfShortTime")(visit.startTime, ['withDate']) || '');
                        break;
                    case "End At":
                        row.push($filter("mfShortTime")(visit.endTime, ['withDate']) || '');
                        break;
                    case "Address":
                        row.push('"' + visit.patientAddress.replace('#', '') + '"' || '" "');
                        break;
                    case "Certifications":
                        if (visit.certifications) {
                            row.push(visit.certifications.join(' | '));
                        } else {
                            row.push('');
                        }
                        break;
                    case "Status":
                        row.push($scope.statusMap[visit.status].text || '');
                        break;
                    default: break;
                }
            });

            rows.push(row);
        });


        var csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "medflyt-visit-export.csv");
        document.body.appendChild(link);

        link.click();
        $scope.isExporting = false;
    }

    $scope.patientChatCountTotal = function (chats) {
        if (!chats) {
            return;
        }

        let counter = 0;

        for (const chat of chats) {
            counter += $scope.patientChatCountMessages(chat)
        }

        return counter;
    }

    $scope.patientChatCountMessages = function (chat) {
        const last = getLastViewed(chat, "AgencyMember");
        let counter = 0;

        for (const message of chat.messages) {
            const hasNotSeen = !last || new Date(message.createdAt).getTime() > last;
            const isMessageFromCaregiver = message.user.type === "Caregiver";

            if (hasNotSeen && isMessageFromCaregiver) {
                counter++;
            }
        }

        return counter;
    }

    $scope.visitChatCount = function (chat) {
        var counter = 0;
        var last = getLastViewed(chat, 'AgencyMember')
        chat.messages.forEach(function (message) {
            if ((!last || new Date(message.createdAt).getTime() > last) && message.user.type === 'Caregiver') counter++;
        });
        return counter;
    };

    const visitIndexById = (id) => !$scope.visits ? -1 : $scope.visits.findIndex(v => id === v.id);

    $scope.setActiveChat = function (visitRow, visitId) {
        if ($rootScope.activeChat) return;
        var i = visitIndexById(visitId);
        if (i < 0) return;
        const visit = $scope.visits[i];
        if (!visit.chats || !visit.chats.length) visit.chats = [];
        $rootScope.activeChatPatient = DatabaseApi.getPatientById(visitRow.patientId);
        $rootScope.activeChatPatientVisit = visitRow;

        $rootScope.activeChatList = visit.chats;
        $rootScope.activeChatList.forEach(function (chat) {
            var caregiver = caregiverById(chat.chatRoomType.caregiverId);
            if (caregiver) {
                chat.photo = caregiver.photoUrl || 'admin/images/blank-profile.jpg';
                chat.displayName = caregiver.firstName + " " + caregiver.lastName;
                chat.caregiverLastViewd = getLastViewed(chat, 'Caregiver');
                chat.agencyLastViewd = getLastViewed(chat, 'AgencyMember');
            }

        });

        if (visit.broadcastedTo) {
            $scope.broadcastedTo = visit.broadcastedTo;
        } else {
            DatabaseApi.getMatchingCaregivers(visit.id).then((caregivers) => {
                $scope.broadcastedTo = caregivers.filter(caregiver => caregiver.appInstalled)
                    .map(c => $scope.caregiversMap[c.id.toString()])
                    .filter(Boolean);
                visit.broadcastedTo = $scope.broadcastedTo;
            });
        }
        $scope.chatFilter.val = '';
    };

    const caregiverById = (id) => {
        if (!$scope.caregiversMap || Object.keys($scope.caregiversMap).length === 0) {
            return false;
        }
        return $scope.caregiversMap[id];
    }

    $scope.dateRange = function (months, rangeFromTo) {
        $scope.dateSelected = months;
        $scope.dateSelectedRange = rangeFromTo;

        var range = new Date();

        if (months === 'week') {
            range.setMonth(range.getMonth(), range.getDate() - 7);
            $scope.isDatePopoverOpen = false;
        } else if (months !== 'custom') {
            $scope.isDatePopoverOpen = false;
            range.setMonth(range.getMonth() - months);
        } else if (months === 'custom') {
            if (!$scope.dateSelectedRange) $scope.dateSelectedRange = 'from';
        }
    };

    $scope.setDate = function (val) {
        $scope.form.startTime = val;
    };

    $scope.altInputFormats = ['M!/d!/yyyy'];

    $scope.dateSelected = 3;
    $scope.dateRange(3);

    const gotVisits = () => {
        return $scope.visits !== undefined;
    };

    const checkAssets = () => {
        const patientsState = $scope.initializeMap['patients'];
        const caregiversState = $scope.initializeMap['caregivers'];
        const agencyMembersState = $scope.initializeMap['agencyMembers'];
        return patientsState && caregiversState && agencyMembersState;
    };

    const setVisitsCaregivers = () => {
        $scope.visits.forEach(visit => {
            if (visit.type === "broadcast") {
                visit.assignedCaregivers = visit.assignedCaregiverIds.map(caregiverId =>
                    caregiverById(caregiverId)
                );
            } else if (visit.type === "unstaffed") {
                visit.assignedCaregivers = visit.visitInstances
                    .filter(visitInstance => visitInstance.caregiverId)
                    .map(visitInstance =>
                        caregiverById(visitInstance.caregiverId)
                    );
            }
        });
    };

    const setVisitsPatients = () => {
        $scope.visits.forEach(visit => {
            if (visit.type === "unstaffed") {
                visit.patient = $scope.patients[visit.visitInstances[0].patientId];
                if (visit.patient) {
                    visit.patientId = visit.patient.id;
                    visit.patientName = visit.patient.firstName + ' ' + visit.patient.lastName;
                }
            }
        });
    };

    const setVisitCoordinators = () => {
        $scope.visits.forEach(visit => {
            if (visit.type === "broadcast") {
                visit.createdByName = visit.createdBy.firstName + ' ' + visit.createdBy.lastName;
                visit.coordinators = [];
                visit.assignedCoordinators.forEach(coordId => {
                    const c = $scope.coordinators.find(coord => coord.id === coordId);
                    if (c !== undefined) {
                        visit.coordinators.push(c);
                    }
                });
            } else if (visit.type === "unstaffed") {
                if (visit.createdBy && !visit.createdBy.id) {
                    visit.createdBy = $scope.coordinators.find(coord => coord.id === visit.createdBy);
                }
                if (visit.createdBy) {
                    visit.createdByName = visit.createdBy.firstName + ' ' + visit.createdBy.lastName;
                }
            }
        });
    };

    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregiversMap = DatabaseApi.caregivers();
        if (!checkAssets()) {
            return;
        }
        if (!gotVisits()) {
            mapVisits();
            return;
        }
        setVisitsCaregivers();
    });

    $rootScope.$on("got_patients_data", () => {
        $scope.patients = DatabaseApi.patients();
        if (!checkAssets()) {
            return;
        }
        if (!gotVisits()) {
            mapVisits();
            return;
        }
        setVisitsPatients();
    });

    $rootScope.$on("got_agency_members", () => {
        initCoordinators();
        if (checkAssets()) {
            initVisits();
        }
    });

    $rootScope.$on("got_visits", () => {
        if (checkAssets()) {
            mapVisits();
        }
    });
    $rootScope.$on("new_visit", () => {
        if (checkAssets()) {
            initVisits($scope.lastFilters, true);
        }
    });
    $rootScope.$on("refresh_visits", () => {
        if (checkAssets()) {
            initVisits($scope.lastFilters, true);
        }
    });
    $rootScope.$on('chat_room_updated', function(event, freshChat) {
        if ($rootScope.activeChat) return;
        const idx = $scope.chats.findIndex(c => c.id == freshChat.id);
        if (idx !== -1) $scope.chats[idx] = freshChat;
        else $scope.chats.push(freshChat);
        if (checkAssets()) {
            mapVisits();
        }
    });

    const withDefault = (val, defaultVal) => {
        if (val !== null && val !== undefined) {
            return val;
        }
        return defaultVal;
    };

    const getFixedVisitDates = (visit) => {
        let startTime = LocalDateTime.parse(visit.visitInstances[0].startTime);
        let endTime = LocalDateTime.parse(visit.visitInstances[0].endTime);

        visit.visitInstances.forEach(visitInstance => {
            const currentStartTime = LocalDateTime.parse(visitInstance.startTime);
            const currentEndTime = LocalDateTime.parse(visitInstance.endTime);

            if (currentStartTime.isBefore(startTime)) {
                startTime = currentStartTime;
            }
            if (currentEndTime.isAfter(endTime)) {
                endTime = currentEndTime;
            }
        });

        return {startTime: startTime.toString(), endTime: endTime.toString()};
    }

    const getFlexibleVisitDates = (visit) => {
        return {
            startTime: visit.startTime,
            endTime: visit.endTime
        }
    }

    const getVisitDates = (visit) => {
        if (visit.flexibleVisitParams !== null) {
            return getFlexibleVisitDates(visit);
        }
        return getFixedVisitDates(visit);
    }

    const sortVisitsByInstancesDate = (visits) => {
        let pastVisits = [];
        let currentVisits = [];

        const nowInstant = Instant.now();

        visits.forEach(visit => {
            let pastInstances = [];
            let currentInstances = [];

            visit.updatedAt = withDefault(visit.updatedAt, visit.createdAt);

            visit.visitInstances.forEach((instance) => {
                const startTimeInVisitTimezone = LocalDateTime.parse(instance.startTime).atZone(ZoneId.of(instance.address.timezone)).toInstant();

                // if the visit is unstaffed there is no start time on the visit object, so I used now to check if the date had passed.
                if(startTimeInVisitTimezone.isBefore(nowInstant)){
                    pastInstances.push(instance);
                }
                else{
                    currentInstances.push(instance);
                }
            })

            if(pastInstances.length > 0){
                visit.isPastVisit = true;
                pastVisits.push({...visit, visitInstances: pastInstances});
            }

            if(currentInstances.length > 0){
                visit.isPastVisit = false;
                currentVisits.push({...visit, visitInstances: currentInstances});
            }
        });

        return {pastVisits, currentVisits};
    }

    const mapBroadcastedVisits = (visits) => {
        const {pastVisits, currentVisits} = sortVisitsByInstancesDate(visits);
        const flexibleVisits = visits.filter(visit => visit.flexibleVisitParams !== null);
        const broadcastedVisits = pastVisits.concat(currentVisits).concat(flexibleVisits);

        broadcastedVisits.forEach((visit) => {
            const patient = $scope.patients[visit.patientId]
            visit.type = "broadcast";

            if (visit.certification !== undefined && visit.certifications === undefined) {
                visit.certifications = visit.certification;
                delete visit.certification;
            }

            const {startTime, endTime} = getVisitDates(visit);

            visit.startTime = startTime;
            visit.endTime = endTime;

            visit.status = VisitStatus.getVisitStatus(visit);

            $scope.statusMap[visit.status].count++;

            visit.canStopBroadcast = !(visit.isPastVisit) && !(visit.status === 'scheduled');
            visit.canBroadcast =
                visit.status === 'unstaffed'
                && !visit.isPastVisit
                && !["DISCHARGED", "DECEASED", "ON_HOLD", "HOSPITALIZED"].includes(patient.status);
            visit.cantBroadcastReason = visit.isPastVisit ? "Cannot broadcast past visits" : "Cannot broadcast for inactive patient";
        })

        return broadcastedVisits;
    }

    const mapUnstaffedVisits = (visits) => {
        const {pastVisits, currentVisits} = sortVisitsByInstancesDate(visits);
        const unstaffedVisits = pastVisits.concat(currentVisits);

        unstaffedVisits.forEach(visit => {
            const patient = $scope.patients[visit.visitInstances[0].patientId]
            visit.type = "unstaffed";
            visit.status = "unstaffed";
            visit.canBroadcast = !visit.isPastVisit && !["DISCHARGED", "DECEASED", "ON_HOLD", "HOSPITALIZED"].includes(patient.status);
            visit.cantBroadcastReason = visit.isPastVisit ? "Cannot broadcast past visits" : "Cannot broadcast for inactive patient";
            visit.canStopBroadcast = false;

            const {startTime, endTime} = getVisitDates(visit);
            visit.startTime = startTime;
            visit.endTime = endTime;
        });

        $scope.statusMap['unstaffed'].count += unstaffedVisits.length;

        return unstaffedVisits;
    }

    const mapVisits = () => {
        initStatuseCounts();
        const visits = DatabaseApi.visits();

        const broadcastedVisits = mapBroadcastedVisits(visits.broadcasts);
        const unstaffedVisits = mapUnstaffedVisits(visits.unstaffed);

        $scope.visits = broadcastedVisits.concat(unstaffedVisits);
        
        setVisitsCaregivers();
        setVisitsPatients();
        setVisitCoordinators();

        $scope.chats = DatabaseApi.chats(null, 'patientChats');

        if (!initFlag && $scope.visits.length) {
            Analytics.event('visit-page-counters', {
                visitCount: $scope.visits.length,
                chatCount: $scope.chats.length
            });
            initFlag = true;
        }

        $scope.visits.forEach(visit => {
            visit.displayVisitInstances = false;

            if (visit.type === "unstaffed") {
                return;
            }
            var flag = false;
            if (visit.assignedCaregiver) {
                flag = visit.assignedCaregiver.id;
            }

            visit.chats = [];
            $scope.chats.forEach(function (chat) {
                if (visit.patientId === chat.chatRoomType.patientId) {
                    if (chat.chatRoomType.caregiverId === flag) flag = false;
                    if (!chat.members.length) {
                        chat.members = [{
                            lastViewed: null,
                            user: {
                                caregiverId: chat.chatRoomType.caregiverId,
                                type: 'Caregiver'
                            }
                        }];
                    }
                    visit.chats.push(chat);
                }
            });

            if (flag) {
                visit.chats.push({
                    id: -1,
                    chatRoomType: {
                        visit: visit,
                        caregiverId: flag,
                        patientId: visit.patientId,
                        type: 'CaregiverPatientChat'
                    },
                    members: [
                        {
                            lastViewed: null,
                            user: {
                                caregiverId: flag,
                                type: 'Caregiver'
                            }
                        }
                    ],
                    messages: []
                });
            }
        });

        if ($rootScope.popupWaiting) {
            $rootScope.openVisitModal({ visit: $rootScope.popupWaiting });
            $rootScope.popupWaiting = false;
        }

        const filteredVisits = getFilteredVisitsByStatuses();
        initTable(filteredVisits);
    }

    const initVisits = (filters, reload) => {
        if ($scope.visits !== undefined && filters === undefined && !reload) {
            return;
        }
        if (!_.isEqual($scope.lastFilters, filters) || reload) {
            $scope.lastFilters = angular.copy(filters);
            DatabaseApi.getVisits(filters).then(res => {
                mapVisits();
            });
        }
    };

    var initFlag = false;

    $scope.preOpenCaregiverModal = function (row) {
        if (!row.assignedCaregiver) return;
        $rootScope.openCaregiverModal(row.assignedCaregiver.id, row.assignedCaregiver);
    };

    $scope.onOpenCaregiverModal = (caregiver) => {
        $rootScope.openCaregiverModal(caregiver.id, caregiver);
    };

    $scope.visitClick = (row, e) => {
        if (row.status === "scheduled" || row.type !== "broadcast" || e.originalEvent.path.find(p=>p.id=="visit-chat")) {
            return;
        }

        $rootScope.openVisitModal({visit: row});
    };

    $scope.filters = {
        statuses: []
    };

    $scope.toggleStatusFilter = function (statusKey) {
        if ($scope.filters.statuses.includes(statusKey)) {
            $scope.filters.statuses = $scope.filters.statuses.filter(x => x !== statusKey);
        } else {
            $scope.filters.statuses.push(statusKey);
        }
        const filteredVisits = getFilteredVisitsByStatuses();
        initTable(filteredVisits);
    };

    const getFilteredVisitsByStatuses = () => {
        let filteredVisits = [];
        Object.assign(filteredVisits, $scope.visits);
        if ($scope.filters.statuses.length > 0) {
            filteredVisits = filteredVisits.filter(visit => $scope.filters.statuses.includes(visit.status));
        }
        return filteredVisits;
    };

    $scope.filterByPatientName = function (term) {
        if ($scope.table) {
            if (term) angular.extend($scope.table.filter(), { patientName: term });
            else angular.extend($scope.table.filter(), { patientName: undefined });
        }
    };

    $scope.openMapModal = function () {
        $uibModal.open({
            templateUrl: 'admin/views/rn-visit-map-modal.html',
            size: 'lg',
            controller: 'rnVisitMapModalCtrl'
        });
    };

    function initTableColumns() {
        $scope.tableColumns = Storage.getObject("visitsDashboardTableSettings");

        if (!$scope.tableColumns || Object.keys($scope.tableColumns).length === 0) {
            $scope.tableColumns = {
                "Patient": true,
                "Caregiver": true,
                "Instances": true,
                "Created By": true,
                "Created": true,
                "Updated": true,
                "Created": true,
                "Starts": true,
                "Ends": true,
                "Location": false,
                "Certifications": true,
                "Status": true,
            };
        }
        $scope.$watch(
            "tableColumns",
            function() {
              if ($scope.tableColumns) {
                Storage.setObject("visitsDashboardTableSettings", $scope.tableColumns);
                initTable($scope.visits);
              }
            },
            true
        );
    }

    function initTable(data) {
        const oldTotal = $scope.table?.total?.() || 0;
        let page = false;
        let filter = {};
        let sorting = { updatedAt: "desc" };
        let count = 25;
        if ($scope.table) {
            page = $scope.table.page();
            filter = $scope.table.filter();
            sorting = $scope.table.sorting();
            count = $scope.table.count();
        }
        $scope.table = new NgTableParams({
            sorting: sorting,
            filter: filter,
            count: count
        }, {
            counts: [10, 25, 50, 100],
            dataset: data
        });
        if (page && oldTotal === $scope.table.total()) $scope.table.page(page);
    }

    $scope.broadcastVisit = (visit) => {
        if (!visit.canBroadcast || $scope.isBroadcasting) {
            return;
        }
        $scope.isBroadcasting = true;
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/broadcast_visits",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            visit.patientId
        );
        const body = {
            type: "Singles",
            visitInstanceIds: visit.visitInstances.map(visitInstance => visitInstance.id),
            assignableCaregiverRadius: 10 * 1609,
            hideEndDateFromCaregiver: false
        };
        DatabaseApi.post(url, body).then((res) => {
            toaster.pop("success", "Success", "Visit was broadcasted successfully");
            $rootScope.$broadcast("refresh_visits");
            generalUtils.scrollToElement("visits-dashboard-wrapper");
        }, (err) => {
            toaster.pop("error", "Oops..", "Failed to broadcast visit");
        }).finally(() => {
            $timeout(() => $scope.isBroadcasting = false, 200);
        });
    };

    $scope.stopBroadcastVisit = (visit) => {
        if (!visit.canStopBroadcast || $scope.isStoppingBroadcast) {
            return;
        }
        $scope.isStoppingBroadcast = true;
        const urlDelete = wildcard(
            "agencies/:agencyId/agency_member/:agencyMemberId/broadcast_visits/:visitBroadcastId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            visit.id
        );
        DatabaseApi.delete(urlDelete).then((res) => {
            toaster.pop("success", "Success", "Visit broadcasted stopped successfully");
            $rootScope.$broadcast("refresh_visits");
            generalUtils.scrollToElement("visits-dashboard-wrapper");
        }, (err) => {
            toaster.pop("error", "Oops..", "Failed to stop broadcast");
        }).finally(() => {
            $timeout(() => $scope.isStoppingBroadcast = false, 200);
        });
    };

    initialize();
};

//! @ngInject
export function animateOnChange() {
    return function (scope, elem, attr) {
        scope.$watchCollection(attr.animateOnChange, function () {
            $animate.addClass(elem, 'on').then(function () {
                $timeout(function () {
                    $animate.removeClass(elem, 'on');
                }, 0);
            });
        });
    };
}